<template>
  <div>
    <el-tooltip class="item" :content="loadingText" effect="dark" placement="top">
      <i @click="exportData" class="el-icon-download" v-if="!loading"></i>
      <i class="el-icon-loading" v-if="loading"></i>
    </el-tooltip>
  </div>
</template>
<script>
import { export_json_to_excel } from '@/unit/Export2Excel.js'
export default {
  data() {
    return {
      loading: false,
      loadingText: '导出数据',
      listData: []
    }
  },
  methods: {
    exportData() {
      this.loading = true
      this.loadingText = '导出中'

      this.$axios
        .post('/trace/lists', {
          count: 999999999,
          mobile: 1
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data.list.length > 0) {
              let listData = res.data.data.list
              listData.map((item, index) => {

                listData[index].seller_text = this.$store.state.userList[
                  item.seller_uid
                ][0]
                listData[index].is_vip_text =
                  item.is_vip == '1' ? '是' : '否'

                if (item.client_level != '') {
                  listData[
                    index
                  ].client_level_text = this.$store.state.cfgData[
                    item.client_level
                  ][0]
                }
                if (item.trace_type != '') {
                  listData[
                    index
                  ].trace_type_text = this.$store.state.cfgData[
                    item.trace_type
                  ][0]
                }
                if (item.trace_status != '') {
                  listData[
                    index
                  ].trace_status_text = this.$store.state.cfgData[
                    item.trace_status
                  ][0]
                }
                if (item.credit_type != '') {
                  listData[
                    index
                  ].credit_type_text = this.$store.state.cfgData[
                    item.credit_type
                  ][0]
                }

              })
              this.listData = listData
              this.getExcel(this.listData)
            } else {
              this.$message.error('当前无导出数据')
            }
          }
        })
    },

    getExcel(res) {
      require.ensure([], () => {
        const tHeader = [
          '客户姓名',
          '手机电话',
          '跟踪次数',
          '销售顾问',
          '重点客户',
          '预计购车日期',
          '车型媒体价',
          '购置税',
          '跟踪日期',
          '竞争品牌',
          '竞争车型',
          '优惠折扣',
          '购买精品金额',
          '客户级别',
          '购买精品明细',
          '下次回访日期',
          '二手车折价',
          '赠送精品明细',
          '邀约到店日期',
          '预估保费',
          '跟踪类型',
          '综合服务费',
          '促进结果',
          '车辆净价',
          '预计总费用',
          '货款方式',
          '跟踪描述',
          '订车日期',
          '交车日期',
          '退订日期',
          '失控日期',
          '战败日期',
          '战败品牌',
          '战败车型',
          '战败说明'
        ]
        const filterVal = [
          'realname',
          'mobile',
          'trace_count',
          'seller_text',
          'is_vip_text',
          'expect_buy_date',
          'media_amount',
          'purchase_tax_amount',
          'trace_date',
          'contend_brand',
          'contend_model',
          'discount_amount',
          'buy_parts_amount',
          'client_level_text',
          'buy_parts_items',
          'next_trace_date',
          'used_car_amount',
          'donate_parts_items',
          'expect_pay_date',
          'insurance_tax_amount',
          'trace_type_text',
          'service_tax_amount',
          'trace_status_text',
          'net_amount',
          'tax_amount',
          'credit_type_text',
          'trace_memo',
          'book_date',
          'deliver_date',
          'unbook_date',
          'lose_date',
          'fall_date',
          'fall_brand',
          'fall_model',
          'fall_memo',
        ]
        const list = this.listData
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '所有跟踪记录')
        this.loading = false
        this.loadingText = '导出数据'
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    }
  }
}
</script>