<template>
  <div class="main" data-title="登记客户列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">客户跟踪记录</h2>
      </div>
    </div>

    <el-card class="main-content" shadow="hover">
      <el-form class="form">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="销售顾问" prop="seller_uid">
              <el-select filterable placeholder="请选择" v-model="seachKey.seller_uid">
                <template v-for="(item, key) in $store.state.userList">
                  <el-option
                    :key="key"
                    :label="item[0]"
                    :value="parseInt(key)"
                    v-if="item[3] == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户姓名">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="seachKey.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="手机号码">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="seachKey.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="促进结果">
              <el-select placeholder="请选择" v-model="seachKey.trace_status">
                <el-option
                  :key="index"
                  :label="item.f1"
                  :value="item.sys_id"
                  v-for="(item, index) in $store.state.cfgList.Result"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="下次跟踪时间">
              <el-date-picker
                align="right"
                end-placeholder="结束日期"
                range-separator="-"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                v-model="seachKey.next_trace_date_begin_end"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item class="label-block" label=" ">
              <el-button
                @click="search"
                @keyup.enter.native="getListData"
                icon="el-icon-search"
                type="primary"
              >搜索</el-button>
              <el-button @click="resetSeachKey">重置</el-button>
              <!-- <el-button @click="expan = true" type="text" v-if="!expan">
                展开
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-button @click="expan = false" type="text" v-else>
                收起
                <i class="el-icon-arrow-up"></i>
              </el-button>-->
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20" v-if="expan">
          
        </el-row>-->
      </el-form>
    </el-card>

    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">
          <!-- <el-button
            @click="$router.push({name: 'customer-trace'})"
            icon="el-icon-arrow-left"
            type="text"
          >返回客户跟踪列表</el-button>-->
          <el-button icon="el-icon-arrow-left" type="text" @click="$router.push({name: 'customer-trace'})">
            返回客户跟踪列表
          </el-button>
        </div>
        <div class="jue-card-btns">
          <el-button
            @click="$router.push({name: 'customer-tracking', query: {client_id: client_id}})"
            class="btn"
            icon="el-icon-edit"
            type="primary"
            v-if="client_id"
          >添加跟踪</el-button>
          <span class="jue-divider"></span>
          <export-record></export-record>
        </div>
      </div>

      <div class="no-data" v-if="total == 0">暂无数据</div>

      <div class="table-wrapper" v-else>
        <el-table
          :data="listData"
          :style="'width: '+$store.state.tableWidth"
          @row-click="selectRow"
          class="table"
          highlight-current-row
        >
          <el-table-column v-if="!this.$route.query.client_id" width="40">
            <template scope="scope">
              <el-radio
                :label="scope.row.client_id"
                @change.native="client_id = scope.row.client_id"
                v-model="client_id"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="客户姓名" prop="realname" width="92"></el-table-column>
          <el-table-column label="手机电话" prop="mobile" width="100"></el-table-column>
          <el-table-column label="销售顾问" prop="seller_uid" width="92">
            <template
              slot-scope="scope"
              v-if="$store.state.userList[scope.row.seller_uid] != undefined"
            >{{$store.state.userList[scope.row.seller_uid][0]}}</template>
          </el-table-column>
          <el-table-column label="检核次数" prop="check_log" width="92">
            <template slot-scope="scope">{{scope.row.check_log.length}}</template>
          </el-table-column>
          <el-table-column label="重点客户" prop="is_vip" width="92">
            <template slot-scope="scope">{{scope.row.is_vip == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="预计购车日期" prop="expect_buy_date" width="118">
            <template
              slot-scope="scope"
            >{{scope.row.expect_buy_date == '0000-00-00' ? '-' : scope.row.expect_buy_date}}</template>
          </el-table-column>
          <el-table-column label="车型媒体价" prop="media_amount" width="104">
            <template slot-scope="scope">￥{{scope.row.media_amount}}</template>
          </el-table-column>
          <el-table-column label="购置税" prop="purchase_tax_amount" width="80">
            <template slot-scope="scope">￥{{scope.row.purchase_tax_amount}}</template>
          </el-table-column>
          <el-table-column label="跟踪日期" prop="trace_date" width="92"></el-table-column>
          <el-table-column label="竞争品牌" prop="contend_brand" width="92"></el-table-column>
          <el-table-column label="竞争车型" prop="contend_model" width="92"></el-table-column>
          <el-table-column label="优惠折扣" prop="discount_amount" width="92">
            <template slot-scope="scope">￥{{scope.row.discount_amount}}</template>
          </el-table-column>
          <el-table-column label="购买精品金额" prop="buy_parts_amount" width="118">
            <template slot-scope="scope">￥{{scope.row.buy_parts_amount}}</template>
          </el-table-column>
          <el-table-column label="客户级别" prop="client_level" width="92">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.client_level] != undefined"
              >{{ $store.state.cfgData[scope.row.client_level][0] }}</span>
            </template>
          </el-table-column>

          <el-table-column label="购买精品明细" prop="buy_parts_items" width="118"></el-table-column>
          <el-table-column label="下次回访日期" prop="next_trace_date" width="118"></el-table-column>
          <el-table-column label="原销售顾问" prop="old_seller_uid" width="104"></el-table-column>
          <el-table-column label="二手车折价" prop="used_car_amount" width="104"></el-table-column>
          <el-table-column label="赠送精品明细" prop="donate_parts_items" width="118"></el-table-column>
          <el-table-column label="邀约到店日期" prop="expect_pay_date" width="200">
            <template slot-scope="scope">
              <span>{{scope.row.invite_income_date_begin}} ~ {{scope.row.invite_income_date_end}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="电话录音" prop="call_audio_file" width="120"></el-table-column> -->
          <el-table-column label="预估保费" prop="insurance_tax_amount" width="92">
            <template slot-scope="scope">￥{{scope.row.insurance_tax_amount}}</template>
          </el-table-column>
          <el-table-column label="跟踪类型" prop="trace_type" width="92">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.trace_type] != undefined"
              >{{ $store.state.cfgData[scope.row.trace_type][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="综合服务费" prop="service_tax_amount" width="104">
            <template slot-scope="scope">￥{{scope.row.service_tax_amount}}</template>
          </el-table-column>
          <el-table-column label="促进结果" prop="trace_status" width="92">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.trace_status] != undefined"
              >{{ $store.state.cfgData[scope.row.trace_status][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" prop="confirm_status" width="92">
            <template slot-scope="scope">{{scope.row.confirm_status == '1' ? '审核中' : '无'}}</template>
          </el-table-column>
          <el-table-column label="车辆净价" prop="net_amount" width="92">
            <template slot-scope="scope">￥{{scope.row.net_amount}}</template>
          </el-table-column>
          <el-table-column label="预计总费用" prop="tax_amount" width="104">
            <template slot-scope="scope">￥{{scope.row.tax_amount}}</template>
          </el-table-column>
          <el-table-column label="贷款方式" prop="credit_type" width="92">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.credit_type] != undefined"
              >{{ $store.state.cfgData[scope.row.credit_type][0] }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="贷款明细" prop="credit_detail" width="120"></el-table-column>
          <el-table-column label="保险明细" prop="insurance_detail" width="120"></el-table-column>-->
          <el-table-column label="跟踪描述" prop="trace_memo" show-overflow-tooltip width="92"></el-table-column>
          <el-table-column label="订车日期" prop="book_date" width="92"></el-table-column>
          <el-table-column label="交车日期" prop="deliver_date" width="92"></el-table-column>
          <el-table-column label="退订日期" prop="unbook_date" width="92"></el-table-column>
          <el-table-column label="失控日期" prop="lose_date" width="92"></el-table-column>
          <el-table-column label="战败日期" prop="fall_date" width="92"></el-table-column>
          <el-table-column label="战败品牌" prop="fall_brand" width="92"></el-table-column>
          <el-table-column label="战败车型" prop="fall_model" width="92"></el-table-column>
          <el-table-column label="战败说明" prop="fall_memo" width="92"></el-table-column>
          <!-- <el-table-column label="其它信息" prop="custom_data" width="120"></el-table-column> -->

          <el-table-column fixed="right" label="操作" width="200px">
            <template slot-scope="scope">
              <el-button
                @click="showCheck(scope.row.trace_id)"
                size="small"
                type="text"
                v-has="'trace-check'"
              >检核</el-button>
              <!-- <el-button
                @click="showCheck(scope.row.trace_id)"
                size="small"
                type="text"
                v-if="scope.row.check_status === 2"
                v-has="'trace-check'"
              >检核</el-button>
              <el-button disabled size="small" type="text" v-else v-has="'trace-check'">已检</el-button>-->
              <span class="jue-divider" v-has="'trace-check'"></span>
              <el-button
                @click="$router.push({name: 'customer-detail', query: { trace_id: scope.row.trace_id}})"
                size="small"
                type="text"
              >查看</el-button>
              <!-- <span class="jue-divider"></span>
              <el-button
                @click="showAllList(scope.row.client_id)"
                size="small"
                type="text"
              >所有记录</el-button>-->

              <span class="jue-divider" v-if="scope.row.editable == 1"></span>
              <el-button
                @click="$router.push({name: 'customer-tracking', query: {trace_id: scope.row.trace_id, client_id: scope.row.client_id}})"
                size="small"
                type="text"
                v-if="scope.row.editable == 1"
              >修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            :page-size="seachKey.count"
            :total="total"
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <change-seller @getListData="getListData" ref="changeSeller"></change-seller>
    <track-check ref="check"></track-check>
  </div>
</template>
<script>
import ChangeSeller from '@/components/ChangeSeller.vue'
import TrackCheck from '@/components/customer/TrackCheck.vue'
import ExportRecord from '@/components/ExportExcel/ExportRecord.vue'
export default {
  components: {
    ChangeSeller,
    TrackCheck,
    ExportRecord
  },
  data() {
    return {
      client_id: '',
      listData: [],
      total: 0,
      expan: false, //搜索展开
      options: {},
      seachKey: {
        seller_uid: null,
        client_id: '',
        realname: '',
        mobile: '',
        trace_status: null,
        next_trace_date_begin: null,
        next_trace_date_end: null,

        next_trace_date_begin_end: null,

        page: 1,
        count: 15
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.$route.query.client_id) {
        this.client_id = this.$route.query.client_id
        this.seachKey.client_id = this.$route.query.client_id
      }

      //获取数据
      this.getListData()
    },
    //点击分页
    handleCurrentChange(val) {
      this.seachKey.page = val
      this.getListData()
    },

    //点击表格一行
    selectRow(row) {
      this.client_id = row.client_id
      // this.$route.query.client_id = row.client_id
    },

    //重置搜索条件
    resetSeachKey() {
      this.seachKey = {
        is_vip: 0,
        status: 0,
        realname: '',
        mobile: '',
        clue_source: '',
        buy_type: '',
        target_model: '',
        has_pick: '',

        page: 1,
        count: 15,
        income_date_begin_end: []
      }
    },

    //设置重点客户
    setClient(data) {
      this.$confirm(
        '此操作将' + data.realname + '为跟踪客户，是否继续',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$axios
            .post('/client/create', {
              income_sys_id: data.sys_id
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getListData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch()
    },
    getOptionItem(type) {
      this.$axios
        .post('/$store.state.cfgData/lists', {
          key: type
        })
        .then(res => {
          if (res.data.code == 200) {
            this.options[type] = res.data.data[0].list.map(item => {
              return { value: item.sys_id, label: item.f1 }
            })
            this.$forceUpdate()
          }
        })
    },

    //点击搜索按钮
    search() {
      this.seachKey.client_id = ''
      this.seachKey.page = 1
      this.getListData()
    },

    showAllList(client_id) {
      this.$router.push({
        name: 'customer-record',
        query: { client_id: client_id }
      })
      this.init()
    },

    //获取列表数据
    getListData() {
      if (this.seachKey.client_id == '') {
        delete this.seachKey.client_id
      }else{
        this.seachKey.client_id = parseInt(this.seachKey.client_id)
      }

      this.$axios.post('/trace/lists', this.seachKey).then(res => {
        if (res.data.code == 200) {
          this.listData = res.data.data.list
          // this.$message.success(res.data.msg)
          this.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    //单选
    selectionChange(index, rows) {
      // this.multipleSelection = rows
    },
    showCheck(id) {
      this.$refs.check.show(id)
    },
    setCheck(status, data) {
      this.getListData()
    }
  },
  watch: {
    'seachKey.next_trace_date_begin_end': function() {
      this.seachKey.next_trace_date_begin = this.seachKey.next_trace_date_begin_end[0]
      this.seachKey.next_trace_date_end = this.seachKey.next_trace_date_begin_end[1]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.footer {
  text-align: right;
  padding: 20px 0;
}

.form {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}
/deep/ .el-radio__label {
  display: none;
}
</style>